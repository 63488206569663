import React, { Fragment } from 'react';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Box, Divider, ListItemButton, Stack, Typography} from '@mui/material';
import {
  CategoryOutlined, CloudOutlined, Description, DirectionsCarOutlined,
  Dns, Https, InsertLink,
  List as ListIcon,
  People, Person,
  Queue, SettingsOutlined,
  Storage, Warning,
  Web
} from '@mui/icons-material';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import {Link, useLocation} from 'react-router-dom';
import CollapsableListItem from '../../elements/List/CollapsableListItem';
// @ts-ignore
import logo from '../../images/logo.png';
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {ElasticIcon, KubernetesIcon} from "../../elements/Icon/Icons";

interface Props extends DrawerProps {}

const Navigation = ({...other}: Props) => {
  const location = useLocation();
  const {user} = React.useContext(UserContext);

  const items = [
    { id: 'DNS beheer', icon: <Dns />, href: '/dns/zone', root: true },
    { id: 'Incidenten', icon: <Warning />, href: '/incident', condition: () => hasRole(user, ['ROLE_INCIDENT_GET']) },
    { id: 'Projecten', icon: <Web />, href: '/project', condition: () => hasRole(user, ['ROLE_PROJECT_GET']) },
    { id: 'Services', icon: <ListIcon />, href: '', items: [
      { id: 'Database', icon: <Storage />, href: '', items: [
        {id: 'Database servers', icon: <></>, href: '/database/server', condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_GET'])},
        {id: 'Databases', icon: <></>, href: '/database', condition: () => hasRole(user, ['ROLE_DATABASE_GET'])},
        {id: 'Toegang', icon: <></>, href: '/database/access', condition: () => hasRole(user, ['ROLE_ADMIN'])},
      ], condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_GET', 'ROLE_DATABASE_GET'])},
      { id: 'Message queue', icon: <Queue />, href: '', items: [
        {id: 'Servers', icon: <></>, href: '/mq/server', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_GET'])},
        {id: 'Gebruikers', icon: <></>, href: '/mq/user', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_USER_GET'])},
      ], condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_GET', 'ROLE_MESSAGE_QUEUE_USER_GET'])},
      { id: 'Kubernetes', icon: <KubernetesIcon />, href: '', items: [
        {id: 'Clusters', icon: <></>, href: '/kubernetes/cluster', condition: () => hasRole(user, ['ROLE_KUBERNETES_CLUSTER_GET'])},
        {id: 'Namespaces', icon: <></>, href: '/kubernetes/namespace', condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_GET'])},
      ], condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_GET', 'ROLE_MESSAGE_QUEUE_USER_GET'])},
      { id: 'Elastic', icon: <ElasticIcon />, href: '', items: [
        {id: 'Deployments', icon: <></>, href: '/elastic/deployment', condition: () => hasRole(user, ['ROLE_ELASTIC_DEPLOYMENT_GET'])},
        {id: 'Gebruikers', icon: <></>, href: '/elastic/user', condition: () => hasRole(user, ['ROLE_ELASTIC_USER_GET'])},
      ], condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_GET', 'ROLE_MESSAGE_QUEUE_USER_GET'])},
      { id: 'Object storage', icon: <Storage />, href: '', items: [
        {id: 'Buckets', icon: <></>, href: '/os/bucket', condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_BUCKET_GET'])},
        {id: 'Accounts', icon: <></>, href: '/os/account', condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_ACCOUNT_GET'])},
      ], condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_BUCKET_GET', 'ROLE_OBJECT_STORAGE_ACCOUNT_GET'])},
      { id: 'Webservices', icon: <DirectionsCarOutlined />, href: '/webservices/user', condition: () => hasRole(user, ['ROLE_WEBSERVICES_USER_GET']) },
      { id: 'Cloudimage', icon: <CloudOutlined />, href: '/cloudimage/token', condition: () => hasRole(user, ['ROLE_CLOUDIMAGE_TOKEN_GET']) },
    ], condition: () => hasRole(user, ['ROLE_DATABASE_GET'])},
    { id: 'SSL', icon: <Https />, href: '/ssl/certificate', condition: () => !user.internal && hasRole(user, ['ROLE_SSL_CERTIFICATE_GET']) },
    { id: 'SSL', icon: <Https />, href: '', items: [
      {id: 'Status', icon: <></>, href: '/ssl/certificate/status', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET'])},
      {id: 'Certificaten', icon: <></>, href: '/ssl/certificate', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET'])},
      {id: 'Nieuw certificaat', icon: <></>, href: '/ssl/certificate/new', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_CREATE'])},
    ], condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET', 'ROLE_SSL_CERTIFICATE_CREATE']) && user.internal},
    { id: 'Resources', icon: <CategoryOutlined />, href: '', items: [
      {id: 'Admin', icon: <></>, href: '/resource/admin'},
      {id: 'Customer', icon: <></>, href: '/resource/customer'},
      { id: 'Kosten', icon: <StackedBarChartIcon />, href: '', items: [
        {id: 'Kubecost (namespace)', icon: <></>, href: '/kubecost'},
        {id: 'Kubecost (klant)', icon: <></>, href: '/kubecost/customer'},
        {id: 'Kosten/Facturatie overzicht', icon: <></>, href: '/resource/customer/custom'}
      ], condition: () => user.roles.includes('ROLE_ADMIN') },
    ], condition: () => user.roles.includes('ROLE_ADMIN') },
    { id: 'Facturen', icon: <Description />, href: '/invoice', condition: () => hasRole(user, ['ROLE_INVOICE_GET']) && (user.internal || (!user.internal && user.customer?.debtorNumber !== null))},
    { id: 'Links', icon: <InsertLink />, href: '/link', condition: () => !user.internal },
    { id: 'Project Management', icon: <People />, href: '/projectmanagement', condition: () => hasRole(user, ['ROLE_PROJECT_MANAGEMENT']) },
    { id: 'Gegevens', icon: <Person />, href: '/customer/data', condition: () => !user.internal },
    { id: 'Instellingen', icon: <SettingsOutlined />, href: '', items: [
      {id: 'Klanten', icon: <People />, href: '/customer', condition: () => hasRole(user, ['ROLE_ADMIN'])},
      {id: 'Rollen', icon: <></>, href: '/role', condition: () => hasRole(user, ['ROLE_ROLE', 'ROLE_ROLE_GET'])},
      {id: 'Gebruikers', icon: <></>, href: '/user', condition: () => hasRole(user, ['ROLE_ADMIN'])},
      {id: 'Logs', icon: <ListIcon />, href: '/dns/log', condition: () => hasRole(user, ['ROLE_ADMIN']) },
      {id: 'Links', icon: <InsertLink />, href: '/link', condition: () => hasRole(user, ['ROLE_PROJECT_LINK_GET']) },
    ], condition: () => hasRole(user, ['ROLE_ROLE', 'ROLE_ROLE_GET'])}
  ];

  return (
    <Drawer variant="permanent" {...other} elevation={2} sx={{border: 'none'}}>
      <Box sx={{pl: 1.5, py: 2.5, height: '80px', bgcolor: '#000', borderBottom: '1px solid rgba(255,255,255,.3)'}}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <img src={logo} alt="Friday logo" height={40}/>
          <Typography component="h6" variant="h6" color="inherit" noWrap sx={{mt: 0.5, fontSize: 17, color: '#fff', ml: 1}}>
            <strong>My</strong> Friday
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <List component="nav" sx={{width: '100%', maxWidth: 360, pt: 0}}>
        {items.map(({id, icon, href, items = [], condition, root = false}, index: number) => {
          if (typeof condition !== 'undefined' && !condition()) {
            return <Fragment key={id}/>;
          }

          if (items.length !== 0) {
            return <CollapsableListItem key={id} id={id} icon={icon} items={items} />;
          }

          return (
            <ListItemButton component={Link} to={href} key={id} sx={{
              py: 2,
              borderTop: index === 0 ? 'none' : '0.5px solid rgba(255,255,255,.3)',
              '&:hover .MuiListItemIcon-root': {
                color: location.pathname === href ? '#fff' : 'rgba(255,255,255,.8)',
              },
              '&:hover .MuiListItemText-root': {
                color: location.pathname === href ? '#fff' : 'rgba(255,255,255,.8)',
              },
            }}>
              <ListItemIcon sx={{
                color: (location.pathname === href || (location.pathname === '/' && root)) ? '#fff' : 'rgba(255,255,255,.6)',
                minWidth: '35px',
              }}>{icon}</ListItemIcon>
              <ListItemText primaryTypographyProps={{fontSize: 14, fontWeight: 'bold'}} sx={{
                color: (location.pathname === href || (location.pathname === '/' && root)) ? '#fff' : 'rgba(255,255,255,.6)',
              }} primary={id} />
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Navigation;
