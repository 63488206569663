import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {Cluster} from "../types";
import {toast} from "react-toastify";
import queryClient from '../services/queryClient';

export const getClusters = () => {
  return useQuery({
    queryKey: ['kubernetesClusters'],
    queryFn: () => client.get('kubernetes/cluster').then(response => response.data),
    initialData: [],
  });
};

export const synchronizeCluster = () => {
  return useMutation({
    mutationFn: async (cluster: Cluster) => {
      return client.post(`kubernetes/cluster/${cluster.id}/synchronize`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['kubernetesClusters']});
      toast.success('Cluster gesynchroniseerd');
    },
  });
};
