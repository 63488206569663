import {useQuery} from "react-query";
import client from "../services/client";

export const getProjectManagementUrl = (id: number) => {
  return useQuery({
    queryKey: ['overview'],
    queryFn: () => client.get(`projectmanagement/overview?customerId=${id}`).then(response => response.data),
    initialData: [],
  });
};


export const getProjectManagementSprintUrl = (id: number, sprintName: string) => {
  return useQuery({
    queryKey: ['overviewSprint', sprintName],
    queryFn: () =>
      client
        .get(`projectmanagement/overview/${sprintName}` + (id === 0 ? '' : `?customerId=${id}`))
        .then((response) => response.data),
    initialData: [],
  });
};

export const getCustomerProjectManagementUrl = (id: number) => {
  return useQuery({
    queryKey: ['overview'],
    queryFn: () => client.get(`customer/${id}/projectmanagement/overview`).then(response => response.data),
    initialData: [],
  });
};

export const getCustomerProjectManagementSprintUrl = (id: number, sprintName: string) => {
  return useQuery({
    queryKey: ['overviewSprint', sprintName],
    queryFn: () =>
      client
        .get(`customer/${id}/projectmanagement/overview/${sprintName}`)
        .then((response) => response.data),
    initialData: [],
  });
};
