import React from 'react';
import { useParams} from "react-router-dom";
import {Chip, Paper} from "@mui/material";
import {getProjectManagementSprintUrl} from "../../api/projectmanagement";
import DataTable from "../../elements/DataTable/DataTable";
import {ColumnDefinitionType, JiraTicket, JiraSquad, TableAction} from "../../types";
import {Search} from "@mui/icons-material";
import TicketDetailDialog from "./TicketDetailDialog";
import LinearProgress from "@mui/material/LinearProgress";

const SprintOverview = () => {
  const { sprintName } = useParams();
  const {id} = useParams();
  const [ticketToView, setTicketToView] = React.useState<null | JiraTicket>(null);
  const {isLoading, data: sprint, isFetching} = getProjectManagementSprintUrl(+(id || 0), (sprintName || ''));

  const columns: Array<ColumnDefinitionType<JiraTicket>> = [
    { key: 'key', title: 'Key', type: 'custom', sortable: true, content: (row) => {
        if (!row.slaTask) {
          return row.key
        }

        return <>{row.key} <Chip label="SLA" size="small" className="MuiChip--warning" /></>;
      }},
    { key: 'summary', title: 'Omschrijving', type: 'string', sortable: true },
    { key: 'status', title: 'Status', type: 'string', sortable: true},
    { key: 'storyPoints', title: 'Storypoints', type: 'integer', sortable: true},
  ];

  const actions: Array<TableAction<JiraTicket>> = [
    { title: 'Bekijken', icon: <Search />, variant: 'primary', onClick: (ticket) => setTicketToView(ticket), type: 'button'},
];


  return (
    <>
      <h1>{sprint.name}</h1>

        {sprint && sprint?.squads?.map((squad: JiraSquad) => (
          <>
            <h3>{squad.name}</h3>
            <div><b>Punten/Reservering:</b> {squad.totalPoints}/{squad.reservation} - <b>SLA/Reservering:</b> {squad.totalSLAPoints}/{squad.slaReservation}</div>
            <LinearProgress variant="determinate" color={"success"} value={squad.pointsDone / squad.totalPoints * 100} />
            <hr/>
            <div key={squad.name}>
              <Paper>
                <DataTable
                  columns={columns}
                  rows={squad.tickets}
                  actions={actions}
                  onRowClick={setTicketToView}
                  loading={isLoading || isFetching}
                />
              </Paper>
            </div>
            <hr/>
          </>
        ))}
        {null !== ticketToView && (
          <TicketDetailDialog open={null !== ticketToView} ticket={ticketToView} onClose={() => setTicketToView(null)} />
        )}
    </>
  );
};

export default SprintOverview;
