import React from 'react';
import {ColumnDefinitionType, JiraTicket} from '../../types';
import {
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';
import DataTable from "../../elements/DataTable/DataTable";

interface Props {
    ticket: JiraTicket;
    open: boolean;
    onClose: () => void;
}

const columns: Array<ColumnDefinitionType<JiraTicket>> = [
  { key: 'key', title: 'Key', type: 'string', sortable: true },
  { key: 'summary', title: 'Omschrijving', type: 'string', sortable: true },
  { key: 'status', title: 'Status', type: 'string', sortable: true}
];

const TicketDetailDialog = ({ ticket, open, onClose }: Props) => {
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={"xl"}>
            <DialogTitle>
                {ticket.key} - {ticket.summary}
            </DialogTitle>
          <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
            <div dangerouslySetInnerHTML={{__html: ticket.description}}/>

            {ticket.children.length > 0 && (
              <>
                <h3>Subtaken</h3>
                <DataTable
                  columns={columns}
                  rows={ticket.children}
                />
              </>
            )}
          </DialogContent>
        </Dialog>
    );
};

export default TicketDetailDialog;
