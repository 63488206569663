import React, {useContext} from 'react';
import {Route, Routes} from 'react-router-dom';
import LogOverview from '../Log/Overview';
import ZoneDetail from '../Dns/Zone/Detail';
import ZoneOverview from '../Dns/Zone/Overview';
import CustomerOverview from '../Customer/Overview';
import CustomerDetail from '../Customer/Detail';
import CustomerZoneOverview from '../Customer/ZoneOverview';
import SpecificCustomerResourceOverview from '../Customer/ResourceOverview';
import KubecostOverview from '../Kubecost/Overview';
import KubecostCustomerDashboard from '../Kubecost/CustomerDashboard/Overview';
import AdminResourceOverview from '../AdminResource/Overview';
import CustomerResourceOverview from '../CustomerResource/Overview';
import KubernetesClusterOverview from '../Kubernetes/Cluster/Overview';
import KubernetesClusterDetail from '../Kubernetes/Cluster/Detail';
import KubernetesNamespaceOverview from '../Kubernetes/Namespace/Overview';
import KubernetesNamespaceDetail from '../Kubernetes/Namespace/Detail';
import KubernetesNamespaceForm from '../Kubernetes/Namespace/Form';
import AuditOverview from '../Audit/Overview';
import AuditDetail from '../Audit/Detail';
import RoleOverview from '../Role/Overview';
import RoleForm from '../Role/Form';
import DatabaseOverview from '../Database/Overview';
import DatabaseForm from '../Database/Form';
import DatabaseDetail from '../Database/Detail';
import DatabaseAccessOverview from '../DatabaseAccess/Overview';
import DatabaseServerOverview from '../DatabaseServer/Overview';
import MessageQueueServerOverview from '../MessageQueueServer/Overview';
import MessageQueueServerForm from '../MessageQueueServer/Form';
import MessageQueueUserOverview from '../MessageQueueUser/Overview';
import MessageQueueUserForm from '../MessageQueueUser/Form';
import MessageQueueUserDetail from '../MessageQueueUser/Detail';
import ProjectOverview from '../Project/Overview';
import ProjectForm from '../Project/Form';
import ProjectDetail from '../Project/Detail';
import LinkForm from '../Link/Form';
import LinkOverview from '../Link/Overview';
import ElasticDeploymentOverview from '../Elastic/Deployment/Overview';
import ElasticDeploymentForm from '../Elastic/Deployment/Form';
import ElasticDeploymentDetail from '../Elastic/Deployment/Detail';
import ElasticUserOverview from '../Elastic/User/Overview';
import ElasticUserDetail from '../Elastic/User/Detail';
import ElasticUserForm from '../Elastic/User/Form';
import CloudimageOverview from '../Cloudimage/Overview';
import WebservicesOverview from '../Webservices/Overview';
import WebservicesDetail from '../Webservices/Detail';
import WebservicesForm from '../Webservices/Form';
import IncidentOverview from '../Incident/Overview';
import IncidentForm from '../Incident/Form';
import InvoiceAdminOverview from '../Invoice/AdminOverview';
import InvoiceCustomerOverview from '../Invoice/CustomerOverview';
import CertificateOverview from '../Ssl/Overview';
import CertificateStatusOverview from '../Ssl/StatusOverview';
import CertificateDetail from '../Ssl/Detail';
import CertificateForm from '../Ssl/Form';
import BucketOverview from '../ObjectStorage/Bucket/Overview';
import BucketForm from '../ObjectStorage/Bucket/Form';
import AccountOverview from '../ObjectStorage/Account/Overview';
import AccountForm from '../ObjectStorage/Account/Form';
import AccountRequestForm from '../ObjectStorage/Account/RequestForm';
import UserOverview from '../User/Overview';
import UserForm from '../User/FormPage';
import UserDetail from '../User/Detail';
import {Paper} from "@mui/material";
import Profile from "../User/Profile";
import ProfileDatabaseForm from "../User/ProfileDatabaseForm";
import {UserContext} from "../../provider/UserProvider";
import Secure from "./Secure";
import CustomOverview from "../CustomerResource/CustomOverview";
import ProjectManagementOverview from "../ProjectManagement/Overview";
import ProjectManagementSprintOverview from "../ProjectManagement/SprintOverview";

const AppRouter: React.FC = () => {
  const {user} = useContext(UserContext);

  return (
    <Routes>
      <Route path="/dns/zone/:id" element={<ZoneDetail />}/>
      <Route path="/dns/zone" element={<ZoneOverview />}/>
      <Route path="/dns/log" element={
        <Paper>
          <LogOverview />
        </Paper>
      }/>
      <Route path="/customer/data" element={<CustomerDetail />}/>
      <Route path="/customer/:id" element={<SpecificCustomerResourceOverview />}/>
      <Route path="/customer/:id/zone" element={<CustomerZoneOverview />}/>
      <Route path="/customer/:id/data" element={<CustomerDetail />}/>
      <Route path="/customer" element={<CustomerOverview />}/>
      <Route path="/kubecost" element={<KubecostOverview />}/>
      <Route path="/kubecost/customer" element={<KubecostCustomerDashboard/>}/>
      <Route path="/resource/admin" element={<AdminResourceOverview />}/>
      <Route path="/resource/customer/custom" element={<CustomOverview />}/>
      <Route path="/resource/customer" element={<CustomerResourceOverview />}/>
      <Route path="/kubernetes/cluster" element={user.secure ? <KubernetesClusterOverview /> : <Secure/>}/>
      <Route path="/kubernetes/cluster/:id" element={user.secure ? <KubernetesClusterDetail /> : <Secure/>}/>
      <Route path="/kubernetes/namespace" element={user.secure ? <KubernetesNamespaceOverview /> : <Secure/>}/>
      <Route path="/kubernetes/namespace/new" element={user.secure ? <KubernetesNamespaceForm /> : <Secure/>}/>
      <Route path="/kubernetes/namespace/:id" element={user.secure ? <KubernetesNamespaceForm /> : <Secure/>}/>
      <Route path="/kubernetes/namespace/:id/statistics" element={user.secure ? <KubernetesNamespaceDetail />: <Secure/>}/>
      <Route path="/audit" element={<AuditOverview/>}/>
      <Route path="/audit/:id" element={<AuditDetail/>}/>
      <Route path="/role" element={<RoleOverview/>}/>
      <Route path="/role/new" element={<RoleForm/>}/>
      <Route path="/role/:id" element={<RoleForm/>}/>
      <Route path="/database" element={user.secure ? <DatabaseOverview/> : <Secure/>}/>
      <Route path="/database/new" element={user.secure ? <DatabaseForm/> : <Secure/>}/>
      <Route path="/database/access" element={user.secure ? <DatabaseAccessOverview/> : <Secure/>}/>
      <Route path="/database/server" element={user.secure ? <DatabaseServerOverview/> : <Secure/>}/>
      <Route path="/database/:id" element={user.secure ? <DatabaseDetail/> : <Secure/>}/>
      <Route path="/mq/server" element={user.secure ? <MessageQueueServerOverview/> : <Secure/>}/>
      <Route path="/mq/server/new" element={user.secure ? <MessageQueueServerForm/> : <Secure/>}/>
      <Route path="/mq/server/:id" element={user.secure ? <MessageQueueServerForm/> : <Secure/>}/>
      <Route path="/mq/user" element={user.secure ? <MessageQueueUserOverview/> : <Secure/>}/>
      <Route path="/mq/user/new" element={user.secure ? <MessageQueueUserForm/> : <Secure/>}/>
      <Route path="/mq/user/:id" element={user.secure ? <MessageQueueUserForm/> : <Secure/>}/>
      <Route path="/mq/user/:id/detail" element={user.secure ? <MessageQueueUserDetail/> : <Secure/>}/>
      <Route path="/elastic/deployment" element={user.secure ? <ElasticDeploymentOverview/> : <Secure/>}/>
      <Route path="/elastic/deployment/new" element={user.secure ? <ElasticDeploymentForm/> : <Secure/>}/>
      <Route path="/elastic/deployment/:id/detail" element={user.secure ? <ElasticDeploymentDetail/> : <Secure/>}/>
      <Route path="/elastic/user" element={user.secure ? <ElasticUserOverview/> : <Secure/>}/>
      <Route path="/elastic/user/new" element={user.secure ? <ElasticUserForm/> : <Secure/>}/>
      <Route path="/elastic/user/:id/detail" element={user.secure ? <ElasticUserDetail/> : <Secure/>}/>
      <Route path="/profile" element={<Profile/>}/>
      <Route path="/profile/database" element={<ProfileDatabaseForm/>}/>
      <Route path="/user" element={<UserOverview/>}/>
      <Route path="/user/new" element={<UserForm/>}/>
      <Route path="/user/:id" element={<UserForm/>}/>
      <Route path="/user/:id/detail" element={<UserDetail/>}/>
      <Route path="/project" element={<ProjectOverview/>}/>
      <Route path="/project/new" element={<ProjectForm/>}/>
      <Route path="/project/:id" element={<ProjectDetail/>}/>
      <Route path="/link" element={<LinkOverview/>}/>
      <Route path="/link/new" element={<LinkForm/>}/>
      <Route path="/link/:id" element={<LinkForm/>}/>
      <Route path="/incident" element={<IncidentOverview/>}/>
      <Route path="/incident/new" element={<IncidentForm/>}/>
      <Route path="/incident/:id" element={<IncidentForm/>}/>
      <Route path="/cloudimage/token" element={<CloudimageOverview/>}/>
      <Route path="/webservices/user" element={<WebservicesOverview/>}/>
      <Route path="/webservices/user/:id" element={<WebservicesDetail/>}/>
      <Route path="/webservices/user/:id/bill" element={<WebservicesForm/>}/>
      <Route path="/invoice" element={user.internal ? <InvoiceAdminOverview/> : <InvoiceCustomerOverview/>}/>
      <Route path="/os/bucket" element={<BucketOverview/>}/>
      <Route path="/os/bucket/new" element={<BucketForm/>}/>
      <Route path="/os/account" element={<AccountOverview/>}/>
      <Route path="/os/account/new" element={<AccountForm/>}/>
      <Route path="/os/account/request" element={<AccountRequestForm/>}/>
      <Route path="/ssl/certificate" element={<CertificateOverview/>}/>
      <Route path="/ssl/certificate/status" element={<CertificateStatusOverview/>}/>
      <Route path="/ssl/certificate/new" element={<CertificateForm/>}/>
      <Route path="/ssl/certificate/:id" element={<CertificateDetail/>}/>
      <Route path="/projectmanagement" element={<ProjectManagementOverview/>}/>
      <Route path="/customer/:id/projectmanagement" element={<ProjectManagementOverview/>}/>
      <Route path="/projectmanagement/:sprintName" element={<ProjectManagementSprintOverview/>}/>
      <Route path="/customer/:id/projectmanagement/:sprintName" element={<ProjectManagementSprintOverview/>}/>
      <Route path="/" element={<ZoneOverview />}/>
    </Routes>
  );
};

export default AppRouter;
