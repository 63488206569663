import React, { useState, useMemo } from "react"
import {useNavigate, useParams} from "react-router-dom"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import {getProjectManagementUrl} from "../../api/projectmanagement";
import {JiraSprint,} from "../../types";
import LinearProgress from "@mui/material/LinearProgress";

function Row({ row }: { row: JiraSprint }) {
  const [open, setOpen] = useState(false)
  const {id} = useParams();
  const navigate = useNavigate()

  return (
    <>
      <TableRow
        hover
        onClick={() => navigate(id ? `/customer/${id}/projectmanagement/${row.name}` : "/projectmanagement/" + row.name)}
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer", backgroundColor: row.active ? 'lightblue' : 'white' }}
      >
        <TableCell>
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{new Date(row.startDate).toLocaleDateString()}</TableCell>
        <TableCell>{new Date(row.endDate).toLocaleDateString()}</TableCell>
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" color={"success"} value={(row.totalDonePoints / row.totalPoints) * 100} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                (row.totalDonePoints / row.totalPoints) * 100,
              )}%`}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          {row.totalPoints} v/d {row.reservation}
        </TableCell>
        <TableCell>
          {row.totalSLAPoints} v/d {row.slaReservation}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Squad</TableCell>
                    <TableCell>Punten/Reservering</TableCell>
                    <TableCell>SLA/Reservering</TableCell>
                    <TableCell>Voortgang</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.squads.map((squad) => (
                      <TableRow key={squad.name}>
                        <TableCell>{squad.name}</TableCell>
                        <TableCell>{squad.totalPoints}/{squad.reservation} </TableCell>
                        <TableCell>{squad.totalSLAPoints}/{squad.slaReservation}</TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "100%", mr: 1 }}>
                              <LinearProgress variant="determinate" color={"success"} value={(squad.pointsDone / squad.totalPoints) * 100} />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <Typography variant="body2" color="text.secondary">{`${Math.round(
                                (squad.pointsDone / squad.totalPoints) * 100,
                              )}%`}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}


export default function Overview() {
  const {id} = useParams();
  const { isLoading, data: sprints, isFetching } = getProjectManagementUrl(+(id || 0))

  const [sorting, setSorting] = useState<{ column: "name"; direction: "asc" | "desc" }>({
    column: "name",
    direction: "asc",
  })
  const visibleColumns = [
    {key: "name", title: "Sprint naam"},
    {key: "startDate", title: "Start datum"},
    {key: "endDate", title: "Eind datum"},
    {key: "totalPoints", title: "Voortgang"},
    {key: "reservation", title: "Gevuld"},
    {key: "totalSLAPoints", title: "SLA"}
  ]

  const sortedAndFilteredData = useMemo(() => {
    if (!sprints) return []
    const filteredData = sprints.filter((sprint: JiraSprint) => {
      return true;
    })

    if (sorting) {
      filteredData.sort((a:JiraSprint, b:JiraSprint) => {
        if (a[sorting.column] < b[sorting.column]) {
          return sorting.direction === "asc" ? -1 : 1
        }
        if (a[sorting.column] > b[sorting.column]) {
          return sorting.direction === "asc" ? 1 : -1
        }
        return 0
      })
    }
    return filteredData
  }, [sprints, sorting])

  const handleSort = (column: any) => {
    setSorting((prev) => ({
      column,
      direction: prev.column === column && prev.direction === "asc" ? "desc" : "asc",
    }))
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom component="div">
          Sprint overzicht
        </Typography>

      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {visibleColumns.map((column) => (
                <TableCell key={column.key}>
                  <TableSortLabel
                    active={sorting.column === column.key}
                    direction={sorting.column === column.key ? sorting.direction : "asc"}
                    onClick={() => handleSort(column.key)}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : (
              sortedAndFilteredData.map((row: JiraSprint) => <Row key={row.name} row={row} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
